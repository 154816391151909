import { Navbar, Container } from 'react-bootstrap'
import Clock from './clock'
import ProjectHeaderInfo from './projectHeaderInfo'
import Weather from './weather'

import { useDispatch } from "react-redux";
import { useSelector } from "../redux/store";
import { AppService } from '../services/appService';
import { showSetting, showProject, showFilter } from "../redux/viewSlice";
import ButtonModel from './button/buttonModel';
import IconButton from './button/IconButton';
import SideMenuIcon from '../assests/images/icon-side-menu.png';

const Header: React.FC<{
	appService: AppService
}> = ({ appService }) => {

	const isAuth = useSelector((state: any) => state.user.isAuth as boolean);
	const project = useSelector((state: any) => state.user.project);
	const isShowSetting = useSelector((state: any) => state.view.showSetting);

	const dispatch = useDispatch();

	var canShowHeader = () => {
		return (isAuth
			&& project != null
			&& !(window.location.pathname.includes('/login') ||
				window.location.pathname.includes('/project')))
	}

	const sideMenuButtonModel: ButtonModel = {
		onClick: () => {
			dispatch(showSetting(!isShowSetting));
			dispatch(showProject(false));
		}
	}

	return (
		<Navbar className='nav-container'>
			<Container fluid>
				{
					canShowHeader() ?
						<ProjectHeaderInfo /> :
						<div style={{ display: 'flex', }}>
							<IconButton viewModel={sideMenuButtonModel} image={SideMenuIcon} />
						</div>
				}
				<div className='nav-weath-clock-div'>
					<div>
						<Weather appService={appService} />
					</div>
					<div>
						<Clock />
					</div>
				</div>
			</Container>
		</Navbar>
	)
}

export default Header