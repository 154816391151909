import { CancelToken } from "axios";
import { AppService } from "./appService";

const dashboardService = (service: AppService) => ({
	fetchData: (formData: any, cancelToken: CancelToken) =>
		service.request(
			'post',
			'statistics',
			formData,
			"",
			cancelToken,
		),

	getZoneDetail: (formData: any) =>
		service.request(
			'post',
			'statistics/zones',
			formData,
		),
	getWeather: () =>
		service.request(
			'post',
			'statistics/weather'
		),
	getControlZone: (formData: any) =>
		service.request(
			'post',
			'statistics/control-zones',
			formData,
		),
	getAlertTable: (formData: any) =>
		service.request(
			'post',
			'statistics/alert-messages',
			formData,
		),
	deleteAllMessage: (formData: any) =>
		service.request(
			'post',
			'statistics/alert-messages/delete',
			formData,
		),
});

export default dashboardService;
