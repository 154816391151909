import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
	name: "user",
	initialState: {
		isAuth: localStorage.getItem('isAuth') ?? false,
		project: (localStorage.getItem('project') != null) ?
			JSON.parse(localStorage.getItem('project')!) : null
	},
	reducers: {
		login: (state, action) => {
			state.isAuth = action.payload;
			localStorage.setItem('isAuth', action.payload);
		},
		setProject: (state, action) => {
			state.project = action.payload;
			localStorage.setItem('project', JSON.stringify(action.payload));
		},
		Logout: (state) => {
			state.isAuth = '';
			state.project = '';
			localStorage.removeItem('project');
			localStorage.removeItem('isAuth');
			localStorage.removeItem('filter');
		},
	},
});

export const { login, Logout, setProject } = UserSlice.actions;

export default UserSlice.reducer;