import { Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { AnyAction } from "redux";
import { DEFAULT_FILTER } from "../model/constants";
import { filter } from "../redux/filterSlice";
import { Logout, setProject } from "../redux/userSlice";
import { showSetting, showProject, showFilter, showLoading, showTokenExpired } from "../redux/viewSlice";
import { AppService } from "./appService";

const authenticationServices = (service: AppService) => ({
	login: (formData: any) =>
		service.request(
			'post',
			'users/login',
			formData,
		),

	logout: () =>
		service.request(
			'post',
			'users/logout',
		),

	refreshToken: () =>
		service.request(
			'post',
			'users/refresh',
		),

});

export const RefreshToken = (
	appService: AppService,
	httpStatusCode: number,
	dispatch: Dispatch<AnyAction>,
	navigate: NavigateFunction,
	successCallBack: () => void,
	failCallBack?: () => void,
) => {
	if (httpStatusCode === 401) {
		appService.authentication
			.refreshToken()
			.subscribe(
				(refreshValue) => {
					dispatch(showLoading(false));
					successCallBack();
				},
				(error) => {
					if (failCallBack != null) {
						failCallBack();
					}
					HandleLogout(dispatch, navigate);
				}
			)
	}
}

export const HandleLogout = (
	dispatch: Dispatch<AnyAction>,
	navigate: NavigateFunction,
	normalLogout: boolean = false
) => {
	dispatch(Logout());
	dispatch(showSetting(false));
	dispatch(showProject(false));
	dispatch(showFilter(false));
	dispatch(showLoading(false));
	dispatch(filter(DEFAULT_FILTER))
	dispatch(setProject(null));
	if (!normalLogout) {
		dispatch(showTokenExpired(true));
	}
	navigate('/login');
}

export default authenticationServices;
