import IconButton from './button/IconButton';
import NormalLabel from './label/normalLabel';
// import HideIcon from '../assests/images/icon-hide.png';
import SideMenuIcon from '../assests/images/icon-side-menu.png';
import FilterFilledIcon from '../assests/images/icon-filter-filled.png';
import FilterIcon from '../assests/images/icon-filter.png';
import ButtonModel from './button/buttonModel';

import { useDispatch } from "react-redux";
import { useSelector } from "../redux/store";
import { ProjectFilter } from '../redux/filterSlice';
import { showSetting, showProject, showFilter } from "../redux/viewSlice";
import NormalButton from './button/normalButton';

import '../i18n'
import { useTranslation } from "react-i18next";

const ProjectHeaderInfo = () => {

	const { t, i18n } = useTranslation()

	const project = useSelector((state: any) => state.user.project);
	const filter = useSelector((state: any) => state.filter.projectFilter as ProjectFilter);

	const isShowSetting = useSelector((state: any) => state.view.showSetting);

	const dispatch = useDispatch();

	const isEng = i18n.language.includes('en')

	const checkIsFiltered = (): boolean => {
		var result = false;

		if (!filter.constructorCode.includes('all')
			|| !filter.typeCode.includes('all')) {
			result = true;
		}
		return result;
	}

	// const hideButtonModel: ButtonModel = {
	// 	onClick: () => { }
	// }

	const sideMenuButtonModel: ButtonModel = {
		onClick: () => {
			dispatch(showSetting(!isShowSetting));
			dispatch(showProject(false));
		}
	}

	const filterButtonModel: ButtonModel = {
		onClick: () => {
			dispatch(showFilter(true));
			dispatch(showProject(false));
		}
	}

	const projectButtonModel: ButtonModel = {
		title: isEng ?
			`[${(project.englishName as string).length > 0 ?
				project.englishName : "-"}]` :
			`[${(project.chineseName as string).length > 0 ?
				project.chineseName : "-"}]`,
		onClick: () => {
			dispatch(showProject(true));
			dispatch(showSetting(false));
		},
		style: {
			color: 'white',
			fontSize: '18px',
			textAlign: 'start',
			lineHeight: '100%'
		}
	}

	return (
		<div className='header-project-div'>
			<div className='header-project-title-menu-div'>
				<div style={{ display: 'flex', }}>
					<IconButton viewModel={sideMenuButtonModel} image={SideMenuIcon} />
				</div>
				<div className='full-size-flex-div'>
					<NormalButton viewModel={projectButtonModel} />
				</div>
				{/* <div style={{ display: 'flex', }}>
					<IconButton viewModel={hideButtonModel} image={HideIcon} />
				</div> */}
			</div>
			<div style={{ width: '58px' }} />
			<div className='full-size-flex-div'>
				<div style={{ paddingRight: '10px' }}>
					<NormalLabel title={`${t('home_contractor')}:`} fontSize={'16'} />
				</div>

				<div className='hidden-overflow-label-div' style={{ maxWidth: 'calc(30%)' }}>
					<NormalLabel title={`${(isEng ? filter.constructorEn ?? "All" : filter.constructor ?? "所有")}`} fontSize={'16'} />
				</div>
				<div style={{ display: 'flex', width: '60%' }}>
					<div style={{ paddingRight: '10px' }}>
						<NormalLabel title={`｜   ${t('home_trade')}:`} fontSize={'16'} />
					</div>
					<div
						className='hidden-overflow-clip-label-div'>
						<NormalLabel title={`${isEng ? filter.typeEn ?? "All" : filter.type ?? "所有"}`} fontSize={'16'} />
					</div>
					<div className='full-size-flex-div' style={{ flex: '1' }}>
						<div style={{ justifyContent: 'center', display: 'flex' }}>
							<IconButton
								viewModel={filterButtonModel}
								image={
									checkIsFiltered() ?
										FilterFilledIcon : FilterIcon
								}
							/>
						</div>

					</div>
				</div>
			</div>
		</div >
	)
}

export default ProjectHeaderInfo