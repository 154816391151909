import axios, { CancelToken } from 'axios';
import { Observable } from 'rxjs';
import authenticationServices from './authenticationService';
import dashboardService from './dashboardService';
import projectServices from './projectServices';
import zonerStatusServices from './zonerStatusService';

export class AppService {

	_baseUrl: string;

	constructor(url: string) {
		this._baseUrl = url;
	}

	request = (
		method: string,
		api: string,
		params?: any,
		contentType?: string,
		cancelToken?: CancelToken,
	) => {
		return new Observable(observer => {
			params
				? console.log(`[API] <<<< - ${api}`, process.env.REACT_APP_ENV === "development" ?
					params : "")
				: console.log(`[API] <<<< - ${api}`);
			axios.defaults.withCredentials = true;
			axios.request({
				method,
				url: this._baseUrl + api,
				params: method === 'get' && params,
				data: method !== 'get' && params,
				headers: {
					'content-type': ((contentType?.length ?? 0 > 0) ? contentType! : 'application/json'),
				},
				withCredentials: true,
				cancelToken: cancelToken
			})
				.then((response) => {
					console.log(`[API] >>>> - ${api}`, process.env.REACT_APP_ENV === "development" ?
						response : "");
					if ((response.data.statusCode >= 200 && response.data.statusCode <= 300)
						&& (response.status >= 200 && response.status <= 300)) {
						observer.next(response);
					} else {
						const errorResponse = {
							api,
							message: response.data.message,
							httpStatusCode: response.data.statusCode,
						};
						observer.error(errorResponse);
					}
				})
				.catch(error => {
					console.log(`[API] xxxx - ${api}`, {
						error,
					});

					if (error.response) {
						const data = error.response.data;
						const message =
							typeof data.status === 'object'
								? data.status.message
								: `[${data.status_code}] ${error.config.url}\n${data.message}`;
						observer.error({
							api,
							message,
							httpStatusCode: error.response.status,
							errorClass: data.status && data.status.errorClass,
						});
					} else {
						observer.error({
							api,
							message: null,
							httpStatusCode: null,
						});
					}
				})
				.finally(function () {
					// always executed
				});
		});
	};

	authentication = authenticationServices(this);
	projectServices = projectServices(this);
	dashboardService = dashboardService(this);
	zonerStatusServices = zonerStatusServices(this);
}
