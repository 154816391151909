import { i18n, TFunction } from "i18next";
import { useState } from "react";
import { CHUNK_SIZE, FILTER_HUMAN } from "../../../model/constants";
import { ContractorDetail } from "../../../model/project/projectModel";
import NormalLabel from "../../label/normalLabel";
import Paging from "../../paging";

export const ContractorContent: React.FC<{
	filterType: string,
	contractorList: ContractorDetail[],
	t: TFunction<"translation">,
	i18n: i18n
}> = ({ filterType, contractorList, t, i18n }) => {

	const [pageIndex, setpageIndex] = useState(1);
	const isEng = i18n.language.includes("en")

	const groupContractorListList = contractorList?.map((value, index) => {
		return index % CHUNK_SIZE === 0 ?
			contractorList.slice(index, index + CHUNK_SIZE) : null
	}).filter((e) => { return e });

	return (
		<div className='paging-container-div' style={{ width: '100%' }}>
			<div className='zone-detail-table-header '>
				<div style={{ flex: '8' }}>
					<NormalLabel title={t('home_contractor')} />
				</div>
				{/* <div style={{ flex: '2', paddingLeft: '10px' }}>
					<NormalLabel title={'工種'} />
				</div> */}
				<div style={{ flex: '1', textAlign: 'end' }}>
					<NormalLabel title={filterType === FILTER_HUMAN ?
						t('home_zone_detail_worker_count') :
						t('home_machine_count')} />
				</div>
			</div >

			<div style={{ height: '11px' }} />
			<div className='div-spacing-zone-line' />
			<div className='paging-div-full-content'>
				{
					(groupContractorListList[pageIndex - 1] ?? [])?.map((value, index) => {
						return (
							<div
								key={index}
								style={{
									paddingTop: '16px',
									paddingBottom: '16px',
									display: 'flex'
								}}
							>
								<div style={{ whiteSpace: 'pre-line', flex: '8' }}>
									<NormalLabel title={isEng ? value.contractorEn : value.contractorZh} />
								</div>
								{/* <div style={{ flex: '2', overflow: 'hidden', paddingLeft: '10px' }}>
									<NormalLabel title={value.trade} />
								</div> */}
								<div style={{ overflow: 'hidden', textOverflow: 'ellipsis', flex: '1', textAlign: 'end' }}>
									<NormalLabel title={value.count} />
								</div>
							</div>
						)
					})
				}
			</div>
			<div className='div-spacing-zone-line' />
			<Paging
				pageSize={groupContractorListList?.length ?? 1}
				onPageClick={(index) => {
					setpageIndex(index);
				}}
			/>
		</div >
	);
}