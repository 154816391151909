import { configureStore } from "@reduxjs/toolkit";
import {
	useSelector as rawUseSelector,
	TypedUseSelectorHook,
} from "react-redux";
import userReducer from "./userSlice";
import filterReducer from "./filterSlice";
import viewReducer from "./viewSlice";

export const store = configureStore({
	reducer: {
		user: userReducer,
		filter: filterReducer,
		view: viewReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;