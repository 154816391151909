const FILTER_HUMAN = "HUMAN";
const FILTER_MANCHINE = "MANCHINE";
const FILTER_HEALTH = "HEALTH";

export { FILTER_HUMAN, FILTER_MANCHINE, FILTER_HEALTH }

export const DEFAULT_FILTER = {
	constructor: '所有',
	constructorEn: 'All',
	constructorCode: 'all',
	constructorUid: 'all',
	type: '所有',
	typeEn: 'All',
	typeCode: 'all',
	filterType: 'HUMAN'
}

export const ALLTRADE = {
	tradeCode: "all",
	nameEng: "All",
	nameChi: "所有",
	descEng: "All",
	descChi: "所有",
}

export const ALLCONTRACTOR = {
	contractorCode: "all",
	idDocument: "所有",
	idNo: "所有",
	englishName: "All",
	chineseName: "所有",
	contactName: "所有",
	contactPhone: "所有",
	contractorUID: 'all',
	address: "所有",
	country: "所有",
	remarks: "所有",
}

export const CHUNK_SIZE = 20;