import React, { useEffect, useState } from 'react'
import { HealthDataModel, Zone } from '../../model/home/homePageModel'
import ButtonModel from '../button/buttonModel'
import { useSelector } from "../../redux/store";
import { ProjectFilter } from '../../redux/filterSlice'
import { AuthorizedWorkerDetail, ControlZone, ZoneInfo, ZoneItemDetail } from '../../model/project/projectModel'
import { AppService } from '../../services/appService'
import { useDispatch } from "react-redux";
import { showLoading } from '../../redux/viewSlice'
import { useNavigate } from 'react-router-dom'
import { FILTER_HEALTH, FILTER_MANCHINE } from '../../model/constants'
import { RefreshToken } from '../../services/authenticationService'
import { SampleZoneInfo } from '../../testing/sampleData'

import '../../i18n'
import { TFunction, useTranslation } from "react-i18next";
import { i18n } from 'i18next'
import { WorkerHealthContent } from './zoneDetailComponent/workerHealthComponent'
import { MechanicalContent } from './zoneDetailComponent/machineComponent'
import { WorkerContent } from './zoneDetailComponent/workerDetailComponent'
import { ContractorContent } from './zoneDetailComponent/contractorDetailComponent'
import { ZoneDetailHeader } from './zoneDetailComponent/zoneHeaderComponent'

const ZoneDetail: React.FC<{
	appService: AppService,
	projectId: string,
	projectUId: string,
	isControlZone: boolean,
	detail?: Zone | ControlZone,
	isMuted: boolean,
	isDemo?: boolean,
	muteToggleHandle: () => void,
	closeCallBack: React.MouseEventHandler
}> = ({ appService, projectUId, projectId, detail, isControlZone = false, isDemo = false, isMuted, muteToggleHandle, closeCallBack }) => {

	const { t, i18n } = useTranslation()

	const filterModel = (useSelector((state: any) => state.filter.projectFilter)) as ProjectFilter;

	const [zoneInfo, setZoneInfo] = useState<ZoneInfo>();
	const [controlZoneInfo, setControlZoneInfo] = useState<ControlZone>();

	const [muted, setMuted] = useState(isMuted);

	var didInit = false;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isEng = i18n.language.includes("en")

	useEffect(() => {
		if (!didInit) {
			// console.log(isControlZone)
			if (!isDemo) {
				if (isControlZone) {
					getControlZoneDetail()
				} else {
					getZoneDetail(true)
				}
			} else {
				setZoneInfo(SampleZoneInfo)
			}
			didInit = true;
		}

		const timer = setInterval(() => {
			if (!isControlZone && filterModel.filterType !== FILTER_HEALTH) {
				getZoneDetail(false)
			}
		}, 1000 * 60 * 15);

		const healthDataTimer = setInterval(() => {
			if (!isControlZone && filterModel.filterType === FILTER_HEALTH) {
				getZoneDetail(false)
			}
		}, 1000 * 60 * 2);

		return () => {
			clearInterval(timer);
			clearInterval(healthDataTimer);
		};

	}, [])

	const getZoneDetail = (showLoad: boolean) => {
		if (showLoad) {
			dispatch(showLoading(true));
		}

		if (detail != null) {
			const zoneName = (detail as Zone).name;

			appService.dashboardService
				.getZoneDetail({
					"projectID": projectId,
					"projectUID": projectUId,
					"contractorID": filterModel.constructorUid !== "all" ? filterModel.constructorUid : null,
					"contractor": filterModel.constructorCode !== "all" ? filterModel.constructorCode : null,
					"zones": [zoneName],
					"trade": filterModel.typeCode !== "all" ? filterModel.type : null,
					"workerType": (filterModel.filterType === FILTER_MANCHINE) ? "machine" : "human",
				})
				.subscribe(
					(value: any) => {
						const workerList = value.data.data;
						setZoneInfo(workerList)
						dispatch(showLoading(false));
					},
					(error) => {
						RefreshToken(
							appService,
							error.httpStatusCode,
							dispatch,
							navigate,
							() => {
								dispatch(showLoading(false));
								getZoneDetail(true)
							}
						);
					}
				)
		} else {
			dispatch(showLoading(false));
		}
	}

	const getControlZoneDetail = () => {
		dispatch(showLoading(true));
		const zoneName = (detail as ControlZone).zone;
		appService.dashboardService
			.getControlZone({
				"projectID": projectId,
				"projectUID": projectUId,
				"zone": zoneName,
				"workerType": (filterModel.filterType === FILTER_MANCHINE) ? "machine" : "human",
			})
			.subscribe(
				(value: any) => {
					setControlZoneInfo(value.data.data)
					dispatch(showLoading(false));
				},
				(error) => {
					RefreshToken(
						appService,
						error.httpStatusCode,
						dispatch,
						navigate,
						() => {
							dispatch(showLoading(false));
							getControlZoneDetail()
						}
					);
				}
			)
	}

	const _buttonModel: ButtonModel = {
		title: '',
		onClick: closeCallBack,
		style: {}
	}

	const convertControlZone = (e: AuthorizedWorkerDetail, isAuth: boolean): ZoneItemDetail => {
		return {
			contractor: e.contractor ?? "-",
			workerId: e.workerId ?? "-",
			workerName: e.workerNameZh ?? e.chineseName ?? e.workerName ?? "-",
			workerNameEn: e.workerNameEn ?? e.englishName ?? e.workerName ?? "-",
			workerNameZh: e.workerNameZh ?? e.chineseName ?? e.workerName ?? "-",
			workerPhoneNo: e.contactPhone ?? "-",
			contractorEn: e.contractorEn ?? "-",
			contractorZh: e.contractorZh ?? "-",
			tradeEn: e.tradeEn ?? "-",
			tradeZh: e.tradeZh ?? "-",
			country: e.country ?? "-",
			trade: e.trade ?? "-",
			zone: e.zone ?? "-",
			zoneFull: e.zoneFull ?? "-",
			locationMethod: e.locationMethod ?? "-",
			checkInTime: e.checkInTime ?? "-",
			checkInDevice: e.checkInDevice ?? "-",
			lastSeenHub: e.lastSeenHub ?? "-",
			lastSeenTime: e.lastSeenTime ?? "-",
			seenCount: e.seenCount ?? 0,
			isMachine: e.isMachine ?? "-",
			age: e.age ?? "-",
			workerImgUrl: e.workerImgUrl ?? "-",
			isAuth: isAuth
		}
	}

	const getZoneList = (): ZoneItemDetail[] => {
		if (isControlZone) {
			var unauthList: ZoneItemDetail[] = []
			var authList: ZoneItemDetail[] = []
			unauthList = controlZoneInfo?.unauthorizedWorkers.list.map(e => convertControlZone(e, false)) ?? []
			authList = controlZoneInfo?.authorizedWorkers.list.map(e => convertControlZone(e, true)) ?? []
			return [...unauthList, ...authList]
		} else {
			const normalZone = ((zoneInfo?.zones.length ?? 0) > 0) ? (zoneInfo?.zones[0].items ?? []) : []

			if (filterModel.filterType === FILTER_HEALTH) {
				const healthZone = normalZone.map((value, index) => {
					const smartagStatusValue = (value.smartagStatus?.length ?? 0) > 0 ? value.smartagStatus! : "{ \"HR\": null, \"B_Temp\": null}"
					const smartagStatus = JSON.parse(smartagStatusValue)
					const healthDataModel: HealthDataModel = {
						workerName: isEng ? value.workerNameEn : value.workerNameZh,
						workerAge: value.age,
						workerTrade: isEng ? value.tradeEn : value.tradeZh,
						workerContractor: isEng ? value.contractorEn : value.contractorZh,
						workerHr: smartagStatus.HR ?? null,
						workerTemp: smartagStatus.B_Temp ?? null,
						isFall: (smartagStatus["alert_Fall"] ?? "0") === "1",
						isNoHelmet: (smartagStatus["alert_helmOff"] ?? "0") === "1",
						isStandStill: (smartagStatus["alert_Still"] ?? "0") === "1",
						isSos: (smartagStatus["alert_SOS"] ?? "0") === "1",
						isSmartagOff: (smartagStatus["alert_BTOFF"] ?? "0") === "1",
					}

					return {
						...value,
						healthData: healthDataModel
					}
				})

				return healthZone;
			} else {
				return normalZone;
			}

		}
	}

	const toggleMuteHandle = (status: boolean) => {
		dispatch(showLoading(true));

		const toggleStatus = status ? "ON" : "OFF"
		const zoneName = (detail as ControlZone).zone
		appService.zonerStatusServices
			.toggleZonerStatus({
				"projectID": projectId,
				"zone": zoneName,
				"status": toggleStatus
			})
			.subscribe((value: any) => {
				muteToggleHandle()
				const updateStatusCode = value.data.data.statusCode
				const updateMessage = value.data.data.message
				const updateStatue = toggleStatus === "ON" ? true : false
				setMuted((updateStatusCode === "200" && updateMessage === "OK") ? updateStatue : false)
				dispatch(showLoading(false));
			},
				(error) => {
					RefreshToken(
						appService,
						error.httpStatusCode,
						dispatch,
						navigate,
						() => {
							dispatch(showLoading(false));
							toggleMuteHandle(status)
						}
					);
				})
	}

	const getMachineTitle = () => {
		if (isControlZone && filterModel.filterType === FILTER_MANCHINE) {
			const zone = (detail as ControlZone);
			return `${zone.zone ?? "-"}  |  ${zone.trade ?? "-"}  |  ${zone.contractor ?? "-"}  |  ${t("home_controlled_zone_detail_mechine_location", {
				location: zone.location ?? "-"
			})}`
		}
		return "";
	}

	return (
		<div
			className='div-box-container-dark-zone-detail'
			style={{
				border: '2px solid rgba(255,143,13,1)',
				display: 'flex',
				height: '100%',
				flexDirection: 'column'
			}}
		>
			{
				detail != null ? ZoneDetailHeader({
					buttonModel: _buttonModel,
					zoneName: `${isControlZone ?
						(detail as ControlZone).zone :
						(detail as Zone).name}`,
					zoneCount: `${isControlZone ?
						(detail as ControlZone).authorizedWorkers.count :
						zoneInfo?.zones[0]?.count ?? "-"}`,
					machineTitle: getMachineTitle(),
					isControlZone: isControlZone,
					filterType: filterModel.filterType,
					controlZoneUnAuthCount: `${isControlZone ? (detail as ControlZone).unauthorizedWorkers.count : null}`,
					isMuted: muted,
					toggleStatus: (status) => {
						toggleMuteHandle(status)
					},
					t: t,
				}) : ZoneDetailHeader({
					buttonModel: _buttonModel,
					zoneName: `-`,
					zoneCount: `-`,
					machineTitle: "- | - | -",
					isControlZone: isControlZone,
					filterType: filterModel.filterType,
					controlZoneUnAuthCount: `-`,
					isMuted: muted,
					toggleStatus: (status) => {
						toggleMuteHandle(status)
					},
					t: t,
				})

			}
			<div className='full-size-flex-row-div'>
				{
					!isControlZone && filterModel.filterType !== FILTER_HEALTH ? <div style={{ width: '40%' }}>
						{
							ContractorContent({
								filterType: filterModel.filterType,
								contractorList: (zoneInfo?.zones.length ?? 0) > 0 ?
									zoneInfo?.zones[0]?.contractors ?? [] : [],
								t: t,
								i18n: i18n
							})
						}
					</div> : null
				}
				<div
					style={{
						width: `${isControlZone || filterModel.filterType === FILTER_HEALTH ?
							"100" : "60"}%`,
						marginLeft: '10px'
					}}
				>
					{
						(filterModel.filterType === FILTER_MANCHINE) ?
							MechanicalContent({
								isControlZone,
								filterType: filterModel.filterType,
								mechanicalList: getZoneList(),
								t: t,
								i18n: i18n,
							}) : filterModel.filterType === FILTER_HEALTH && !isControlZone ?
								WorkerHealthContent({
									wokerList: getZoneList(),
									t: t,
									i18n: i18n
								}) : WorkerContent({
									wokerList: getZoneList(),
									isControlZone,
									t: t,
									i18n: i18n,
								})
					}
				</div>
			</div>
		</div >
	)
}

export default ZoneDetail;