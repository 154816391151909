import React from 'react'
import { Container } from 'react-bootstrap'
import NormalLabel from '../label/normalLabel'
import '../../i18n'
import { useTranslation } from "react-i18next";

const TotalWorker: React.FC<{
	workCount: string,
	lastUpdateTime: string,
}> = ({ workCount, lastUpdateTime }) => {
	const { t } = useTranslation()

	return (
		<Container
			className='div-box-container'
			style={{ width: '100%', height: '100%' }}
		>
			<div
				style={{
					width: '100%',
					paddingTop: '20px',
					paddingLeft: '20px',
				}}
			>
				<div style={{ paddingBottom: '5px' }} >
					<NormalLabel title={t("home_total_worker")} />
					<NormalLabel title={`${t("home_as_of_time")} ${lastUpdateTime}`} />
				</div>
				<div>
					<NormalLabel title={workCount} fontSize={'110'} />
				</div>
			</div>
		</Container>
	)
}

export default TotalWorker;